import { useMemo } from "react"
import { useSelector } from "@app/models"

export default () => {
  const {
    filterOptions: {
      providersFilters: { providerIds },
    },
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const hasProviderIds = Boolean(providerIds)

  const sortedProvidersData = useSelector((state) => state.groupData.providers)

  return useMemo(
    () =>
      hasProviderIds
        ? sortedProvidersData.filter((providerItem: ProviderBaseType) =>
            providerIds?.includes(providerItem.providerid)
          )
        : sortedProvidersData,
    [providerIds, sortedProvidersData]
  )
}
