import React, { useEffect } from "react"
import { format, parseISO } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import api from "@app/services/api"
import Button from "@app/components/Button"
import { handleApiError } from "@app/utils"
import Collapse from "@app/components/Collapse"
import { useDispatch, useSelector } from "@app/models"
import { DefaultCalendarColor } from "@app/utils/constants"
import { calculateQuickAssigmentJobViolations } from "@app/services/quickAssignments"
import { ViolationQuickAssignment } from "@app/containers/spa/WhiteboardCalendar/data"
import { Provider as CalendarProvider } from "@app/containers/spa/WhiteboardCalendar/data"

import css from "./SelectedJobMenu.module.scss"

interface SelectedJobMenuProps {
  providersData: CalendarProvider[]
}

export const SelectedJobMenu = ({ providersData }: SelectedJobMenuProps) => {
  const dispatch = useDispatch()
  const { selectedJob } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )
  const { quickAssignments, quickAssignmentViolations } = useSelector(
    (state) => state.quickAssignments
  )

  const handleCancel = () => {
    dispatch.calendarEvents.setSelectedProvider(undefined)
    dispatch.calendarEvents.setSelectedJob(undefined)
    dispatch.quickAssignments.resetQuickAssignments()
  }

  useEffect(() => {
    if (selectedJob) {
      calculateQuickAssigmentJobViolations(
        quickAssignments,
        providersData,
        (assignmentCount, violations) => {
          dispatch.quickAssignments.updateQuickAssignments({
            assignmentCount,
            quickAssignmentViolations: violations,
          })
        }
      )
    }
  }, [quickAssignments, selectedJob])

  const handleDeleteQuickAssign = async (
    assignment: ViolationQuickAssignment
  ) => {
    const id = assignment?.draft_eventid || assignment?.eventid
    const deleteAssigment = assignment?.draft_eventid
      ? api.deleteDraftEvent
      : api.deleteEvent

    if (id) {
      try {
        await deleteAssigment(id)
        dispatch.calendarEvents.getCalendarData()
        dispatch.quickAssignments.removeQuickAssignmentAndViolation(id)
      } catch (error) {
        handleApiError
      }
    }
  }

  const handleUndoAll = () => {
    for (const violation of quickAssignmentViolations) {
      handleDeleteQuickAssign(violation)
    }
  }

  return (
    <aside className={css.container}>
      <div>
        <p className={css.headerText}>Quick Assign mode active for:</p>
        <div className={css.headerWrapper}>
          <p className={css.valueText}>
            <span
              className={css.jobCircle}
              style={{ background: selectedJob?.color || DefaultCalendarColor }}
            />
            {selectedJob?.name}
          </p>
          <div>
            <Button
              shape="pill"
              variant="light"
              size="sm"
              onClick={handleCancel}
            >
              Change Quick Assign Job
            </Button>
          </div>
        </div>
      </div>

      <div>
        <p className={css.headerText}>Number of assignments</p>
        <span className={css.valueText}>{quickAssignments?.length || 0}</span>
      </div>

      <Collapse title={`Violations (${quickAssignmentViolations.length})`}>
        <div className={css.accordionContainer}>
          <div className={css.buttonRightAlignContainer}>
            <button className={css.undoAllButton} onClick={handleUndoAll}>
              Undo All Violations
            </button>
          </div>
          <ul className={css.violationItemsContainer}>
            {quickAssignmentViolations.map((violation, index) => (
              <li className={css.violationItem} key={index}>
                <span className={css.authorName}>
                  {violation.provider.display_name}
                </span>
                <span className={css.normalText}>
                  {format(
                    utcToZonedTime(parseISO(violation.edate), "UTC"),
                    "eeee do"
                  )}
                </span>
                <span className={css.normalText}>
                  {violation.rulesApplied[0].message}
                </span>
                <button
                  className={css.undoAssignButton}
                  onClick={() => handleDeleteQuickAssign(violation)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Collapse>
    </aside>
  )
}
