import api from "@app/services/api"

export const updateAssignment = (
  providerids: number[],
  previousAssignment: AssignmentBaseType
) => {
  const additionalEventAssignments =
    previousAssignment.additional_event_assignments
  if (
    !previousAssignment.split_shift &&
    !previousAssignment?.additional_event_assignments?.length &&
    providerids.length === 1
  ) {
    if (previousAssignment.eventid) {
      return api.updateSingleAssignment({
        jobid: previousAssignment.jobid,
        providerid: providerids[0],
        edate: previousAssignment.edate,
        existingProviderid: previousAssignment.providerid,
      })
    } else {
      return api.updateDraftEvent({
        jobid: previousAssignment.jobid,
        providerid: providerids[0],
        edate: previousAssignment.edate,
        existingProviderid: previousAssignment.providerid,
      })
    }
  } else if (additionalEventAssignments?.length || providerids.length > 1) {
    const updateMultipleAssignments = previousAssignment.draft_eventid
      ? api.updateDraftMultipleAssignments
      : api.updateMultipleAssignments
    return updateMultipleAssignments(
      previousAssignment.jobid,
      previousAssignment.edate,
      providerids
    )
  } else {
    return new Promise((_resolve, reject) => {
      reject({
        status: "error",
        message:
          "Update assignment call must be either a single or multiple assignment draft event.",
      })
    })
  }
}

export const createSingleAssignment = (
  providerids: number[],
  jobid: number,
  edate: string,
  draftMode: boolean
) => {
  if (providerids.length === 1) {
    if (draftMode) {
      return api.createDraftEvent({
        edate,
        jobid: Number(jobid),
        providerid: providerids[0],
      })
    } else {
      return api.createSingleAssignment({
        edate,
        jobid: Number(jobid),
        providerid: providerids[0],
      })
    }
  } else {
    const multipleAssignmentsApi = draftMode
      ? api.createDraftMultipleAssignemnts
      : api.createMultipleAssignemnts
    return multipleAssignmentsApi(jobid, edate, providerids)
  }
}
