import React, { useCallback, useMemo, useState } from "react"
import { Select } from "@app/components/Form"
import { H5 } from "@app/components/Typography"
import CheckIcon from "@material-ui/icons/Check"
import { useDispatch, useSelector } from "@app/models"
import { DefaultCalendarColor } from "@app/utils/constants"
import { filterJobTypesOptions } from "@app/utils/constants"
import { Job } from "../../../../data"
import css from "./FilterJobs.module.scss"

const initializeSelectedJobs = (jobsData: Job[], jobIds?: number[]): Job[] => {
  if (!jobIds) {
    return jobsData
  } else {
    return jobsData.filter((jobItem) => jobIds.includes(jobItem.jobid))
  }
}

interface JobsFiltersProps {
  jobsData: Job[]
  onClose: () => void
}

export const FilterJobs = ({ jobsData, onClose }: JobsFiltersProps) => {
  const { calendarConfig } = useSelector((state) => state.calendarEvents)

  const {
    filterOptions: { jobsFilters },
  } = calendarConfig

  const [jobSubFilters, setJobSubFilters] = useState<number[]>(
    jobsFilters.activeSubFilter ?? []
  )

  const [selectedJobs, setSelectedJobs] = useState<Job[]>(() =>
    initializeSelectedJobs(jobsData, jobsFilters?.jobIds)
  )

  const dispatch = useDispatch()

  const filteredData = useMemo(
    () =>
      jobSubFilters.length > 0
        ? jobsData.filter((item) => jobSubFilters?.includes(item.job_typeid))
        : jobsData,
    [jobsData, jobSubFilters]
  )

  const handleJobSubFilterList = useCallback(
    (selectedTypeIds: number[]) => {
      setJobSubFilters(selectedTypeIds)

      const currentFilteredData =
        selectedTypeIds.length > 0
          ? selectedJobs?.filter((item) =>
              selectedTypeIds.includes(item.job_typeid)
            )
          : selectedJobs

      setSelectedJobs(currentFilteredData)
    },
    [selectedJobs]
  )

  const handleSelectedJob = (job: Job) => {
    const isSelected = selectedJobs?.some(
      (selectedJob) => selectedJob.jobid === job.jobid
    )
    if (!isSelected) {
      setSelectedJobs([...(selectedJobs ?? []), job])
    } else {
      setSelectedJobs(
        selectedJobs?.filter((selectedJob) => selectedJob.jobid !== job.jobid)
      )
    }
  }

  const handleApplyFilters = () => {
    dispatch.calendarEvents.saveFilterOptions({
      jobsFilters: {
        activeSubFilter: jobSubFilters,
        jobIds:
          selectedJobs === jobsData
            ? undefined
            : selectedJobs.map((selectedJob) => selectedJob.jobid),
      },
    })
    onClose()
  }

  const handleCancel = () => {
    setSelectedJobs([])
    onClose()
  }

  const handleSelectAllJobs = useCallback(() => {
    const hasSubFilters = jobSubFilters.length > 0 && jobSubFilters.length < 3
    const allJobsSelected = selectedJobs?.length === jobsData.length
    const allFilteredJobsSelected = filteredData.length === selectedJobs?.length

    if (hasSubFilters) {
      if (!allFilteredJobsSelected) {
        setSelectedJobs(filteredData)
      } else {
        setSelectedJobs([])
      }
    } else {
      if (allJobsSelected) {
        setSelectedJobs([])
      } else {
        setSelectedJobs(jobsData)
      }
    }
  }, [jobsData, selectedJobs, jobSubFilters, filteredData])

  const selectAllDisplayedText = useMemo(() => {
    const hasSubFilters = jobSubFilters.length > 0 && jobSubFilters.length < 3
    const allJobsSelected = selectedJobs?.length === jobsData.length
    const allFilteredJobsSelected = filteredData.length === selectedJobs?.length

    if (hasSubFilters) {
      return allFilteredJobsSelected ? "Unselect All" : "Select All"
    } else {
      return allJobsSelected ? "Unselect All" : "Select All"
    }
  }, [jobsData, selectedJobs, jobSubFilters, filteredData])

  return (
    <div className={css.jobContainer}>
      <div className={css.innerContainer}>
        <Select
          multiple
          value={jobSubFilters}
          options={filterJobTypesOptions}
          onChange={handleJobSubFilterList}
          placeholder="All Jobs Types"
          className={css.selectMultiJob}
        />

        <div className={css.selectAllContainer}>
          <div className={css.selectButton} onClick={handleSelectAllJobs}>
            {selectAllDisplayedText}
          </div>
        </div>

        <div className={css.customSelect}>
          <ul>
            {filteredData?.length === 0 && <div>No jobs found</div>}
            {filteredData?.map((job) => (
              <li
                key={job.jobid}
                className={css.customOption}
                style={{
                  borderLeft: `10px solid ${job.color ?? DefaultCalendarColor}`,
                }}
                onClick={() => handleSelectedJob(job)}
              >
                <div className={css.optionInnerContainer}>
                  <H5 ellipsis>{job.abbrev}</H5>
                  <div
                    className={`${css.checkboxContainer} ${
                      selectedJobs?.some(
                        (selectedJobItem) => selectedJobItem.jobid === job.jobid
                      )
                        ? css.checked
                        : css.unchecked
                    }`}
                  >
                    {selectedJobs?.some(
                      (selectedJobItem) => selectedJobItem.jobid === job.jobid
                    ) ? (
                      <CheckIcon className={css.checkIcon} />
                    ) : (
                      <div className={css.uncheckedIcon}></div>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={css.buttonContainer}>
        <button className={css.cancelButton} onClick={handleCancel}>
          Cancel
        </button>
        <button className={css.applyButton} onClick={handleApplyFilters}>
          Apply
        </button>
      </div>
    </div>
  )
}
