import React, { useState } from "react"
import Modal from "@app/components/Modal"
import { FormItem, Input, useForm } from "@app/components/Form"
import Form from "react-bootstrap/Form"
import { useSelector } from "@app/models"
import css from "./ScheduleRange.module.scss"
import { H4 } from "@app/components/Typography"
import ProviderAssignment from "./ProviderAssignment"

type Props = {
  showScheduleRange: boolean
  onHide: React.Dispatch<React.SetStateAction<any>>
}

type FormFieldsType = {
  start_date: string
  end_date: string
  jobid: string | number
}

export default (props: Props) => {
  const { showScheduleRange, onHide } = props
  const [preview, setPreview] = useState(false)
  const [submitHandler, setSubmitHandler] = useState<Function | undefined>()

  const { jobs } = useSelector((state) => state.groupData)

  const groupedJobs = jobs.reduce((r, a) => {
    r[a.job_typeid] = r[a.job_typeid] || []
    r[a.job_typeid].push(a)
    return r
  }, Object.create(null))

  const JobTypes = ["Call", "Work", "Resident"]

  const { control, getValues, setValue, trigger, reset } =
    useForm<FormFieldsType>({
      mode: "onChange",
      schema: (yup) =>
        yup.object().shape({
          start_date: yup
            .date()
            .required("Start date is an invalid date")
            .max(yup.ref("end_date"), "Start date must be less than end date"),
          end_date: yup
            .date()
            .required("End date is an invalid date")
            .min(
              yup.ref("start_date"),
              "End date must be greater than start date"
            ),
          jobid: yup.string().required(),
        }),
    })

  const hideModal = () => {
    reset()
    setPreview(false)
    onHide(false)
  }

  const { jobid, start_date, end_date } = getValues()

  return (
    <Modal
      title="Schedule Date Range"
      size="md"
      show={showScheduleRange}
      onHide={hideModal}
      buttons={[
        {
          text: preview ? "No" : "Cancel",
          variant: "outline-default",
          onClick: () => {
            if (preview) {
              setPreview(false)
              return false
            } else {
              hideModal()
              return true
            }
          },
        },
        {
          text: preview ? "Yes" : "Next",
          variant: "outline-primary",
          onClick: (e) => {
            if (preview) {
              submitHandler && submitHandler()
              hideModal()
            } else {
              trigger().then((valid) => valid && setPreview(true))
            }
            return false
          },
        },
      ]}
    >
      {preview ? (
        <ProviderAssignment
          jobid={Number(jobid)}
          start_date={start_date}
          end_date={end_date}
          setSubmitHandler={setSubmitHandler}
        />
      ) : (
        <div className={css.scheduleRangeBody}>
          <div className="mb-5">
            <H4 className="mb-3">
              Select the date range you would like to schedule
            </H4>
            <FormItem control={control} label="Start Date" name="start_date">
              <Input
                type="date"
                onChange={() => trigger(["start_date", "end_date"])}
              />
            </FormItem>
            <FormItem control={control} label="End Date" name="end_date">
              <Input
                type="date"
                onChange={() => trigger(["start_date", "end_date"])}
              />
            </FormItem>
          </div>
          <div className="mb-5">
            <H4 className="mb-3">
              And select the job you would like to schedule to see a list of
              available and unavailable provider to help make a more informed
              decision on which provider to schedule.
            </H4>
            <FormItem label="Jobs">
              <Input type="hidden" control={control} name="jobid"></Input>
              <Form.Control
                as="select"
                onChange={(v) => {
                  setValue("jobid", v.target.value)
                  trigger(["jobid"])
                }}
                className={css.jobSelect}
              >
                <option value="">Select a job...</option>
                {Object.keys(groupedJobs).map((key) => {
                  const label = JobTypes[Number(key) - 1]
                  return (
                    <optgroup key={label} label={label}>
                      {groupedJobs[key].map((job: any) => (
                        <option key={job.jobid} value={job.jobid}>
                          {job.name}
                        </option>
                      ))}
                    </optgroup>
                  )
                })}
              </Form.Control>
            </FormItem>
          </div>
        </div>
      )}
    </Modal>
  )
}
