import { useMemo } from "react"
import { useSelector } from "@app/models"

export default (date: string) => {
  const {
    calendarConfig: {
      filterOptions: {
        jobsFilters: { jobIds },
      },
    },
    dailyAssignments: { dailyFlags },
  } = useSelector((state) => state.calendarEvents)

  const filterFunc = (flag: ScheduleDateType) => {
    if (!jobIds) return true

    return jobIds.includes(flag.jobid)
  }

  const flags = dailyFlags[date] || []

  return useMemo(() => [...flags].filter(filterFunc), [jobIds, flags])
}
