import { createModel } from "@rematch/core"
import { RootModel } from "."
import { ViolationQuickAssignment } from "@app/containers/spa/WhiteboardCalendar/data"

type QuickAssignmentsState = {
  quickAssignmentViolations: ViolationQuickAssignment[]
  quickAssignments: ViolationQuickAssignment[]
}

export default createModel<RootModel>()({
  state: {
    quickAssignmentViolations: [],
    quickAssignments: [],
  } as QuickAssignmentsState,
  reducers: {
    addQuickAssignment(state, payload: ViolationQuickAssignment) {
      state.quickAssignments.push(payload)
    },
    setQuickAssignmentViolations(state, payload: ViolationQuickAssignment[]) {
      state.quickAssignmentViolations = payload
    },
    removeQuickAssignmentViolation(state, eventid: number | undefined) {
      state.quickAssignmentViolations = state.quickAssignmentViolations.filter(
        (violation) =>
          violation.eventid !== eventid &&
          violation.draft_eventid !== eventid &&
          Number(`${violation.eventid}${violation.providerid}`) !== eventid &&
          Number(`${violation.draft_eventid}${violation.providerid}`) !==
            eventid
      )
    },
    removeQuickAssignment(state, eventid: number | undefined) {
      state.quickAssignments = state.quickAssignments.filter((assignment) => {
        assignment.eventid !== eventid &&
          assignment.draft_eventid !== eventid &&
          Number(`${assignment.eventid}${assignment.providerid}`) !== eventid &&
          Number(`${assignment.draft_eventid}${assignment.providerid}`) !==
            eventid
      })
    },
    removeMultipleQuickAssignment(state, identifier: number) {
      state.quickAssignments = state.quickAssignments.filter(
        (assignment) =>
          (assignment.draft_eventid || assignment.eventid) !== identifier
      )
    },
    removeQuickAssignmentAndViolation(state, eventid: number | undefined) {
      state.quickAssignments = state.quickAssignments.filter(
        (assignment) =>
          assignment.eventid !== eventid &&
          assignment.draft_eventid !== eventid &&
          Number(`${assignment.eventid}${assignment.providerid}`) !== eventid &&
          Number(`${assignment.draft_eventid}${assignment.providerid}`) !==
            eventid
      )
      state.quickAssignmentViolations = state.quickAssignmentViolations.filter(
        (violation) => {
          violation.eventid !== eventid &&
            violation.draft_eventid !== eventid &&
            Number(`${violation.eventid}${violation.providerid}`) !== eventid &&
            Number(`${violation.draft_eventid}${violation.providerid}`) !==
              eventid
        }
      )
    },
    resetQuickAssignments(state) {
      state.quickAssignmentViolations = []
      state.quickAssignments = []
    },
  },
  effects: (dispatch) => ({
    async updateQuickAssignments(payload: {
      assignmentCount: number
      quickAssignmentViolations: ViolationQuickAssignment[]
    }) {
      dispatch.quickAssignments.setQuickAssignmentViolations(
        payload.quickAssignmentViolations
      )
    },
  }),
})
