import { useMemo } from "react"
import { useSelector } from "@app/models"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"
import { mapFunc } from "@app/containers/spa/WhiteboardCalendar/utils/mapFunc"

export default (date: string) => {
  const {
    calendarConfig: {
      filterOptions: {
        jobsFilters: { jobIds },
      },
    },
    dailyAssignments: { dailyEvents, dailyDraftEvents },
  } = useSelector((state) => state.calendarEvents)

  const filterFunc = (assignment: GroupedAssignment) => {
    if (!jobIds) return true

    return jobIds.includes(assignment.jobid ?? assignment.job?.jobid)
  }

  const events = dailyEvents[date] || []
  const draftEvents = dailyDraftEvents[date] || []

  return useMemo(
    () =>
      [...events, ...draftEvents]
        .map((assignment) => mapFunc(assignment, filterFunc))
        .filter(filterFunc),
    [jobIds, events, draftEvents]
  )
}
