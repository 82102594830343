import { useMemo } from "react"
import { useSelector } from "@app/models"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"
import { mapFunc } from "@app/containers/spa/WhiteboardCalendar/utils/mapFunc"

export default (date: string): GroupedAssignment[] => {
  const {
    calendarConfig: {
      filterOptions: {
        jobsFilters: { jobIds },
        providersFilters: { providerIds },
      },
    },
    dailyAssignments: { dailyEvents, dailyDraftEvents },
  } = useSelector((state) => state.calendarEvents)

  const filterFunc = (assignment: GroupedAssignment) => {
    const jobIdsCondition = !jobIds
      ? true
      : jobIds.includes(assignment.job?.jobid)
    const providerIdsCondition = !providerIds
      ? true
      : providerIds.includes(assignment.provider.providerid)

    const isMultiAssignmentFilterCondition =
      !!assignment.eventid &&
      assignment.additional_event_assignments?.some(
        (additionalAssignment: any) =>
          providerIds?.includes(additionalAssignment.provider.providerid)
      )

    const isMultiDraftAssignmentFilterCondition =
      !!assignment.draft_eventid &&
      assignment.additional_event_assignments?.some(
        (additionalDraftAssignment: any) =>
          providerIds?.includes(additionalDraftAssignment.provider.providerid)
      )

    return (
      jobIdsCondition &&
      (providerIdsCondition ||
        isMultiAssignmentFilterCondition ||
        isMultiDraftAssignmentFilterCondition)
    )
  }

  const events = dailyEvents[date] || []
  const draftEvents = dailyDraftEvents[date] || []

  return useMemo(
    () =>
      [...events, ...draftEvents]
        .map((assignment) => mapFunc(assignment, filterFunc))
        .filter(filterFunc),
    [jobIds, providerIds, events, draftEvents]
  )
}
