export function* dateRangeGenerator(
  start: string,
  end: string,
  step: number = 1
): Generator<string> {
  // Convert the start and end strings into Date objects.
  const startDate = new Date(start)
  const endDate = new Date(end)
  // Initialize the current date to the start date.
  let currentDate = startDate

  // Continue yielding dates until the current date exceeds the end date.
  while (currentDate <= endDate) {
    // Yield the current date as a string in ISO date format.
    yield currentDate.toISOString().slice(0, 10)
    // Increment the current date by the step value in days.
    currentDate.setDate(currentDate.getDate() + step)
  }
}

/*
24// Usage example:
25const dateGenerator = dateRangeGenerator('2023-04-01', '2023-04-04', 1);
26console.log(dateGenerator.next().value); // '2023-04-01'
27console.log(dateGenerator.next().value); // '2023-04-02'
28console.log(dateGenerator.next().value); // '2023-04-03'
29console.log(dateGenerator.next().value); // '2023-04-04'
30console.log(dateGenerator.next().done); // true (indicates that the generator is finished)
31
*/

// const startDate = "2023-04-01";
// const endDate = "2023-04-04";
// for (const date of dateRangeGenerator(startDate, endDate)) {
//   console.log(date);
// }
