import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"

export const mapFunc = (
  assignment: GroupedAssignment,
  filterFunction: (assignment: GroupedAssignment) => boolean | undefined
) => {
  if (
    assignment.additional_event_assignments &&
    assignment.additional_event_assignments.length > 0
  ) {
    return {
      ...assignment,
      additional_event_assignments: assignment.additional_event_assignments
        .map((addAssignment) => ({
          ...addAssignment,
          job: assignment?.job,
        }))
        .filter(filterFunction),
    }
  }

  if (
    assignment.split_shiftid &&
    assignment.additional_split_event_assignments
  ) {
    return {
      ...assignment,
      additional_split_event_assignments:
        assignment.additional_split_event_assignments.filter(filterFunction),
    }
  }

  return assignment
}
