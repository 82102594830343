import { useMemo } from "react"
import { useSelector } from "@app/models"

export default () => {
  const {
    filterOptions: {
      jobsFilters: { jobIds },
    },
    isMissingAssignmentsFilterActive,
    isUnderstaffedJobsHighlightActive,
  } = useSelector((state) => state.calendarEvents.calendarConfig)

  const sortedJobsData = useSelector((state) => state.groupData.jobs)

  const hasJobIds = Boolean(jobIds)

  const isJobItemMatching = (jobItem: JobAssignment) => {
    const hasMatchingJobId = jobIds?.includes(jobItem.jobid)
    const hasEmptyAssignments =
      jobItem.show_empty_assignments &&
      jobItem.job_day_types.some((dayType) => dayType.assign === 1)
    const hasMultipleAssignmentsEnabled = jobItem.multiple_assignments

    if (
      isMissingAssignmentsFilterActive &&
      hasJobIds &&
      isUnderstaffedJobsHighlightActive
    ) {
      return (
        hasEmptyAssignments && hasMatchingJobId && hasMultipleAssignmentsEnabled
      )
    } else if (isMissingAssignmentsFilterActive && hasJobIds) {
      return hasEmptyAssignments && hasMatchingJobId
    } else if (
      isMissingAssignmentsFilterActive &&
      isUnderstaffedJobsHighlightActive
    ) {
      return hasEmptyAssignments && hasMultipleAssignmentsEnabled
    } else if (hasJobIds && isUnderstaffedJobsHighlightActive) {
      return hasMatchingJobId && hasMultipleAssignmentsEnabled
    } else if (isMissingAssignmentsFilterActive) {
      return hasEmptyAssignments
    } else if (isUnderstaffedJobsHighlightActive) {
      return hasMultipleAssignmentsEnabled
    }

    return hasMatchingJobId
  }

  return useMemo(
    () =>
      hasJobIds ||
      isMissingAssignmentsFilterActive ||
      isUnderstaffedJobsHighlightActive
        ? (sortedJobsData as unknown as JobAssignment[]).filter(
            isJobItemMatching
          )
        : (sortedJobsData as unknown as JobAssignment[]),
    [
      hasJobIds,
      isMissingAssignmentsFilterActive,
      isUnderstaffedJobsHighlightActive,
      sortedJobsData,
    ]
  )
}
