import { useMemo } from "react"
import { useSelector } from "@app/models"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"
import { mapFunc } from "@app/containers/spa/WhiteboardCalendar/utils/mapFunc"

export default (date: string) => {
  const {
    calendarConfig: {
      filterOptions: {
        providersFilters: { providerIds },
      },
    },
    dailyAssignments: { dailyEvents, dailyDraftEvents },
  } = useSelector((state) => state.calendarEvents)

  const filterFunc = (assignment: GroupedAssignment) => {
    if (!providerIds) return true

    const providerIdsCondition = providerIds.includes(
      assignment.provider.providerid
    )

    const isMultiAssignmentFilterCondition =
      !!assignment.eventid &&
      assignment.additional_event_assignments?.some((additionalAssignment) =>
        providerIds.includes(additionalAssignment.provider.providerid)
      )

    const isMultiDraftAssignmentFilterCondition =
      !!assignment.draft_eventid &&
      assignment.additional_event_assignments?.some(
        (additionalDraftAssignment) =>
          providerIds.includes(additionalDraftAssignment.provider.providerid)
      )

    return (
      providerIdsCondition ||
      isMultiAssignmentFilterCondition ||
      isMultiDraftAssignmentFilterCondition
    )
  }

  const events = dailyEvents[date] || []
  const draftEvents = dailyDraftEvents[date] || []

  return useMemo(
    () =>
      [...events, ...draftEvents]
        .map((assignment) => mapFunc(assignment, filterFunc))
        .filter(filterFunc),
    [providerIds, events, draftEvents]
  )
}
