import React, { Fragment, useEffect, useState } from "react"
import Form from "react-bootstrap/Form"
import { Col } from "react-bootstrap"
import { FormItem, useForm } from "@app/components/Form"
import IconButton from "@material-ui/core/IconButton"
import ClearIcon from "@material-ui/icons/Clear"
import { useFieldArray, Controller } from "@app/components/Form"

import api from "@app/services/api"
import { useCaniuseFeature } from "@app/utils/hooks"
import useRequest from "@app/utils/request"
import { formatDate, handleApiError } from "@app/utils"
import { DateFormatter } from "@app/utils/constants"
import { useDispatch, useSelector } from "@app/models"
import ProvidersCustomSelectComponent from "@app/containers/spa/WhiteboardCalendar/component/ProviderCustomSelect"
import ToggleSwitch from "@app/components/Toggle/Toggle"

import css from "./ScheduleRange.module.scss"

type Props = {
  title?: string | React.ReactNode
  content?: string | React.ReactNode
  className?: string
  jobid: number
  start_date: string
  end_date: string
  setSubmitHandler: React.Dispatch<React.SetStateAction<any>>
}

type FormFieldsType = {
  providerids: { providerid: number }[]
}

export default (props: Props) => {
  const { jobid, start_date, end_date, setSubmitHandler } = props

  const dispatch = useDispatch()
  const { jobs } = useSelector((state) => state.groupData)
  const currentJob = jobs.find((job: any) => job.jobid === jobid)
  const jobStarttime = currentJob!.starttime
  const jobEndtime = currentJob!.endtime

  const { draft_mode_scheduling } = useSelector(
    (state) => state.calendarEvents.calendarConfig.rulesConfig
  )

  const draftModeEnabled = useCaniuseFeature("draft_mode", {
    scope: "group",
  })

  const initToggleDraftState = (): boolean => {
    if (!draftModeEnabled) return false

    return draft_mode_scheduling
  }

  const [draftMode, setDraftMode] = useState<boolean>(initToggleDraftState())

  const { data: jobSettings } = useRequest([
    api.getRangeJobMultipleAssignmentSettings,
    jobid,
    start_date,
    end_date,
  ])

  const { control, handleModalSubmit, watch } = useForm<FormFieldsType>({
    defaultValues: {
      providerids: [{ providerid: 0 }],
    },
    schema: (yup) =>
      yup.lazy(() =>
        yup.object().shape({
          providerids: yup.array().of(
            yup.object().shape({
              providerid: yup
                .number()
                .required()
                .min(1, "You must choose a provider"),
            })
          ),
        })
      ),
  })

  const submitForm = (fields: FormFieldsType) => {
    const providerids = fields.providerids.map(
      (provider) => provider.providerid
    )

    const data = {
      jobid,
      start_date,
      end_date,
      providerids,
      draft_mode: draftMode,
    }

    api.scheduleRange(data).then((res: any) => {
      dispatch.calendarEvents.getEvents()
      dispatch.calendarEvents.getDraftEvents()
    }, handleApiError)
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: "providerids",
  })

  const rows = watch("providerids")

  useEffect(() => {
    setSubmitHandler(() => handleModalSubmit(submitForm))
  }, [rows, draftMode])

  const handlePublishToggle = (value?: boolean) => {
    setDraftMode(!value)
  }

  const renderToggleSwitch = () => (
    <div className={css.toggleSwitchContainer}>
      <ToggleSwitch
        key={`${draftMode}`}
        value={!draftMode}
        onToggle={handlePublishToggle}
        disabled={!draftModeEnabled}
      />
      <span className={css.toggleSwitchLabel}>
        {draftMode ? "Unpublished" : "Published"}
      </span>
    </div>
  )

  return (
    <div className={css.customPopoverContent}>
      <div className={css.jobHeader}>
        <p className="mb-2">{currentJob!.name}</p>
        {renderToggleSwitch()}
      </div>
      <Form>
        <div className="row">
          <Form.Group as={Col} className="col-md-6 mb-0">
            <Form.Label className={css.labelPopup}>Provider(s)</Form.Label>
          </Form.Group>
          <Form.Group as={Col} className="col-md-6 mb-0">
            <Form.Label className={css.labelPopup}>Shift Times</Form.Label>
          </Form.Group>
        </div>

        {fields.map((row, index) => (
          <div className="row" key={row.id}>
            <Form.Group
              as={Col}
              controlId={`providerSelect-${index}`}
              className="col-md-6 mb-0"
            >
              <FormItem
                className={css.formItem}
                label=""
                layout={[0, 12]}
                name={`providerids[${index}].providerid`}
                control={control}
              >
                <Controller
                  name="providerid"
                  key={row.id}
                  render={({ field: { onChange, value } }) => (
                    <ProvidersCustomSelectComponent
                      onChange={onChange}
                      jobid={String(jobid)}
                      edate={jobSettings?.date || start_date}
                      defaultProviderId={value}
                      filterProviderIds={rows
                        .map((provider) => provider.providerid)
                        .filter((providerid) => providerid !== 0)}
                    />
                  )}
                />
              </FormItem>
            </Form.Group>

            <Form.Group
              as={Col}
              controlId={`shiftTimes-${index}`}
              className={"col-md-6 mb-0"}
            >
              <Fragment>
                <div className={css.labelTimes}>
                  <span>
                    {formatDate(
                      new Date(`2023-01-01T${jobStarttime}`),
                      DateFormatter.hourMinute12h
                    )}{" "}
                    to{" "}
                    {formatDate(
                      new Date(`2023-01-01T${jobEndtime}`),
                      DateFormatter.hourMinute12h
                    )}
                  </span>

                  {rows.length > 1 && (
                    <IconButton
                      aria-label="upload picture"
                      component="span"
                      onClick={() => remove(index)}
                      style={{
                        width: 22,
                        height: 22,
                        padding: 0,
                        marginLeft: 10,
                        backgroundColor: "#FFFFFF",
                        color: "#3080DF",
                        border: "1px solid #CDD9DE",
                        visibility: "visible",
                      }}
                    >
                      {" "}
                      <ClearIcon
                        fontSize="small"
                        style={{ color: "#A2B0BC" }}
                      />
                    </IconButton>
                  )}
                </div>
              </Fragment>
            </Form.Group>
          </div>
        ))}
        {rows.length < jobSettings?.maximum && rows[0].providerid !== 0 && (
          <div className="row">
            <div className="col-12 text-center my-3">
              <a
                onClick={() => append({ providerid: 0 })}
                className={css.addProviderLink}
              >
                + Add Provider
              </a>
            </div>
          </div>
        )}
      </Form>
    </div>
  )
}
