import { createModel } from "@rematch/core"
import { RootModel } from "."
import api, { mutate } from "@app/services/api"
import {
  Provider,
  IRequest,
  IRequestStatus,
  IRequestType,
} from "@app/containers/spa/WhiteboardCalendar/data"
import { GroupedAssignment } from "@app/services/getGroupedDayAssignments"
import { orderJobsFunc } from "@app/utils/sorts"

// Need to track assignments, draft assignments, flags, provider requests, and days off
type EventsType = {
  assignments: GroupedAssignment[]
  draftEvents: GroupedAssignment[]
  flags: ScheduleDateType[]
  daysOff: Dayoff[]
  holidays: Holiday[]
  requests: IRequest[]
  vacations: Vacation[]
  notes: Note[]
}

type DailyAssignmentType = {
  dailyEvents: Record<string, GroupedAssignment[]>
  dailyDraftEvents: Record<string, GroupedAssignment[]>
  dailyFlags: Record<string, ScheduleDateType[]>
  dailyDaysOff: Record<string, Dayoff[]>
  dailyVacations: Record<string, Vacation[]>
  dailyNotes: Record<string, Note[]>
}

type DayoffFilters = {
  activeSubFilter: number[]
  requestStatusIds?: number[]
  requestTypeIds?: number[]
  showVacations?: boolean
}

type CalendarFilterOptions = {
  timePeriod: "Day" | "Week" | "Month"
  timePeriodMultiple: number
  startDate: string
  endDate: string
  providersFilters: {
    activeSubFilter: number[]
    providerIds?: number[]
  }
  jobsFilters: {
    activeSubFilter: number[]
    jobIds?: number[]
  }
  dayoffFilters: DayoffFilters
}

type SchedulingRuleType = {
  id: string
  abbrev: string
  enabled: boolean
  title: string
}

type RulesConfigType = {
  apply_scheduling_rule: boolean
  draft_mode_scheduling: boolean
  calendar_start_date: number
  display_job_times: boolean
  compact_list_view: boolean
  rules: SchedulingRuleType[]
}

type HighlightOptions = {
  highlightProviders?: Provider[]
  activeSubFilter: number[]
}

type CalendarConfigType = {
  calendarType: "calendar" | "list"
  selectedDate: Date
  filterOptions: CalendarFilterOptions
  listViewType?: "job" | "provider"
  selectedProvider?: Provider | undefined
  selectedJob?: JobAssignment | undefined
  isMissingAssignmentsFilterActive: boolean
  isUnderstaffedJobsHighlightActive: boolean
  isHighlightedChangesActive: boolean
  highlightOptions: HighlightOptions
  rulesConfig: RulesConfigType
}

type StateType = {
  events: EventsType
  dailyAssignments: DailyAssignmentType
  requestStatus: IRequestStatus[]
  requestTypes: IRequestType[]
  calendarConfig: CalendarConfigType
}

interface FilteredEventsType extends EventsType {
  jobsFilteredAssignments: GroupedAssignment[]
  jobsFilteredDraftEvents: GroupedAssignment[]
  providersFilteredAssignments: GroupedAssignment[]
  providersFilteredDraftEvents: GroupedAssignment[]
}

export default createModel<RootModel>()({
  state: {
    events: {
      assignments: [],
      draftEvents: [],
      flags: [],
      daysOff: [],
      requests: [],
      vacations: [],
      holidays: [],
      notes: [],
    },
    dailyAssignments: {
      dailyEvents: {},
      dailyDraftEvents: {},
      dailyFlags: {},
      dailyDaysOff: {},
      dailyVacations: {},
      dailyNotes: {},
    },
    requestStatus: [],
    requestTypes: [],
    calendarConfig: {
      calendarType: "calendar",
      selectedDate: new Date(),
      filterOptions: {
        timePeriod: "Month",
        timePeriodMultiple: 1,
        startDate: "",
        endDate: "",
        providersFilters: { activeSubFilter: [] },
        jobsFilters: { activeSubFilter: [] },
        dayoffFilters: {
          activeSubFilter: [],
          requestStatusIds: undefined,
          requestTypeIds: undefined,
          showVacations: undefined,
        },
      },
      listViewType: "job",
      selectedProvider: undefined,
      missingAssignmentsFilter: false,
      isUnderstaffedJobsHighlightActive: false,
      isHighlightedChangesActive: false,
      rulesConfig: {
        draft_mode_scheduling: false,
        apply_scheduling_rule: false,
        calendar_start_date: 1,
        display_job_times: true,
        compact_list_view: false,
        rules: [] as SchedulingRuleType[],
      },
      highlightOptions: {
        highlightProviders: [],
        activeSubFilter: [],
      },
    },
  } as unknown as StateType,
  reducers: {
    setCalendarConfig(state, payload: Partial<CalendarConfigType>) {
      return {
        ...state,
        calendarConfig: {
          ...state.calendarConfig,
          ...payload,
        },
      }
    },
    setDailyEvents(state, payload: Record<string, GroupedAssignment[]>) {
      return {
        ...state,
        dailyAssignments: {
          ...state.dailyAssignments,
          dailyEvents: {
            ...state.dailyAssignments.dailyEvents,
            ...payload,
          },
        },
      }
    },
    setDailyDraftEvents(state, payload: Record<string, GroupedAssignment[]>) {
      return {
        ...state,
        dailyAssignments: {
          ...state.dailyAssignments,
          dailyDraftEvents: {
            ...state.dailyAssignments.dailyDraftEvents,
            ...payload,
          },
        },
      }
    },
    setDailyFlags(state, payload: Record<string, ScheduleDateType[]>) {
      return {
        ...state,
        dailyAssignments: {
          ...state.dailyAssignments,
          dailyFlags: {
            ...state.dailyAssignments.dailyFlags,
            ...payload,
          },
        },
      }
    },
    setDailyDaysOff(state, payload: Record<string, Dayoff[]>) {
      return {
        ...state,
        dailyAssignments: {
          ...state.dailyAssignments,
          dailyDaysOff: {
            ...state.dailyAssignments.dailyDaysOff,
            ...payload,
          },
        },
      }
    },
    setDailyVacations(state, payload: Record<string, Vacation[]>) {
      return {
        ...state,
        dailyAssignments: {
          ...state.dailyAssignments,
          dailyVacations: {
            ...state.dailyAssignments.dailyVacations,
            ...payload,
          },
        },
      }
    },
    setDailyNotes(state, payload: Record<string, Note[]>) {
      return {
        ...state,
        dailyAssignments: {
          ...state.dailyAssignments,
          dailyNotes: {
            ...state.dailyAssignments.dailyNotes,
            ...payload,
          },
        },
      }
    },
    setEvents(state, payload: Partial<EventsType>) {
      const { assignments, draftEvents, flags } = payload
      const sortAssignments =
        assignments && [...assignments].sort(orderJobsFunc)
      const sortDraftEvents =
        draftEvents && [...draftEvents].sort(orderJobsFunc)
      const sortFlags = flags && [...flags].sort(orderJobsFunc)
      return {
        ...state,
        events: {
          ...state.events,
          ...payload,
          assignments: sortAssignments || state.events.assignments,
          draftEvents: sortDraftEvents || state.events.draftEvents,
          flags: sortFlags || state.events.flags,
        },
      }
    },
    setStatusAndTypesList(state, payload: any) {
      return {
        ...state,
        requestStatus: payload.requestStatus,
        requestTypes: payload.requestTypes,
      }
    },
    setFilterOptions(state, payload: Partial<CalendarFilterOptions>) {
      return {
        ...state,
        calendarConfig: {
          ...state.calendarConfig,
          filterOptions: {
            ...state.calendarConfig.filterOptions,
            ...payload,
          },
        },
      }
    },
    setDayoffFilters(state, payload: Partial<DayoffFilters>) {
      return {
        ...state,
        calendarConfig: {
          ...state.calendarConfig,
          filterOptions: {
            ...state.calendarConfig.filterOptions,
            dayoffFilters: {
              ...state.calendarConfig.filterOptions.dayoffFilters,
              ...payload,
            },
          },
        },
      }
    },
    setHighlightOptions(
      state,
      payload: Partial<CalendarConfigType["highlightOptions"]> | undefined
    ) {
      return {
        ...state,
        calendarConfig: {
          ...state.calendarConfig,
          highlightOptions: {
            ...state.calendarConfig.highlightOptions,
            ...payload,
          },
        },
      }
    },
    setSelectedProvider(state, payload: Provider | undefined) {
      return {
        ...state,
        calendarConfig: {
          ...state.calendarConfig,
          selectedProvider: payload,
        },
      }
    },
    setSelectedJob(state, payload: JobAssignment | undefined) {
      return {
        ...state,
        calendarConfig: {
          ...state.calendarConfig,
          selectedJob: payload,
        },
      }
    },
    setCalendarListViewMode(state, payload: "job" | "provider") {
      return {
        ...state,
        calendarConfig: {
          ...state.calendarConfig,
          listViewType: payload,
        },
      }
    },
    setMissingAssignmentsFilter(state, payload: boolean) {
      return {
        ...state,
        calendarConfig: {
          ...state.calendarConfig,
          isMissingAssignmentsFilterActive: payload,
        },
      }
    },
    setUnderstaffedJobsHighlight(state, payload: boolean) {
      return {
        ...state,
        calendarConfig: {
          ...state.calendarConfig,
          isUnderstaffedJobsHighlightActive: payload,
        },
      }
    },
    setHighlightedChangesActive(state, payload: boolean) {
      return {
        ...state,
        calendarConfig: {
          ...state.calendarConfig,
          isHighlightedChangesActive: payload,
        },
      }
    },
    setSchedulingRules(state, payload: RulesConfigType) {
      return {
        ...state,
        calendarConfig: {
          ...state.calendarConfig,
          rulesConfig: payload,
        },
      }
    },
  },
  effects: (dispatch) => ({
    // Deprecated
    // These kinds of reducers are easy to use but with performance concerns, we will removed them gradually
    async getEvents(_data: void, rootState) {
      const { startDate, endDate } =
        rootState.calendarEvents.calendarConfig.filterOptions

      mutate([api.getAssignments, startDate, endDate])
    },
    // Deprecated
    async getDraftEvents(_data: void, rootState) {
      const { startDate, endDate } =
        rootState.calendarEvents.calendarConfig.filterOptions

      mutate([api.getDraftEvents, startDate, endDate])
    },
    async getFlags(_data: void, rootState) {
      const { startDate, endDate } =
        rootState.calendarEvents.calendarConfig.filterOptions

      mutate([api.getFlags, startDate, endDate])
    },
    async getDaysOff(_data: void, rootState) {
      const { startDate, endDate } =
        rootState.calendarEvents.calendarConfig.filterOptions

      mutate([api.getDayoffs, startDate, endDate])
    },
    async getRequests(_data: void, rootState) {
      const { startDate, endDate } =
        rootState.calendarEvents.calendarConfig.filterOptions

      mutate([api.getPendingRequests, startDate, endDate])
    },
    async getVacations(_data: void, rootState) {
      const { startDate, endDate } =
        rootState.calendarEvents.calendarConfig.filterOptions

      mutate([api.getVacations, startDate, endDate])
    },
    async getHolidays(_data: void, _rootState) {
      mutate([api.getHolidays])
    },
    async getNotes(_data: void, rootState) {
      const { startDate, endDate } =
        rootState.calendarEvents.calendarConfig.filterOptions

      mutate([api.getNotesList, startDate, endDate])
    },
    // Deprecated
    async getCalendarData(_data: void, _rootState) {
      this.getEvents()
      this.getDraftEvents()
      this.getFlags()
      this.getDaysOff()
      this.getRequests()
      this.getVacations()
      this.getNotes()
    },
    // Deprecated
    // TODO: Remove this, as these should be replaced by the returned created/updated data instead of refreshing everything
    async getCalendarDataWithoutEvents(_date: void) {
      this.getFlags()
      this.getDaysOff()
      this.getRequests()
      this.getVacations()
      this.getNotes()
    },
    async getRequestStatusAndType(_data: void, rootState) {
      const requestTypes = await api.getRequestTypes()
      const requestStatus = await api.getRequestStatuses()
      dispatch.calendarEvents.setStatusAndTypesList({
        requestStatus,
        requestTypes,
      })
    },
    async getSchedulingRules(_data: void, rootState) {
      const schedulingRules = await api.getSchedulingRules()
      const {
        draft_mode_settings: { draft_mode_enabled },
      } = await api.getDraftModeSettings()
      const {
        group: { enabled_features },
      } = rootState.users.currentUser

      const draftModeGroupEnabled = enabled_features.includes("draft_mode")

      dispatch.calendarEvents.setSchedulingRules({
        ...schedulingRules,
        draft_mode_scheduling:
          draftModeGroupEnabled &&
          draft_mode_enabled &&
          schedulingRules.draft_mode_scheduling,
      })
    },
    async getFilterOptions(_data: void, _rootState) {
      const filterOptions = await api.getCalendarFilter()
      if (
        filterOptions.provider_filter &&
        Object.keys(filterOptions.provider_filter).length > 0
      ) {
        dispatch.calendarEvents.setFilterOptions({
          providersFilters: filterOptions.provider_filter,
        })
      }
      if (
        filterOptions.job_filter &&
        Object.keys(filterOptions.job_filter).length > 0
      ) {
        dispatch.calendarEvents.setFilterOptions({
          jobsFilters: filterOptions.job_filter,
        })
      }
      if (
        filterOptions.dayoff_filter &&
        Object.keys(filterOptions.dayoff_filter).length > 0
      ) {
        dispatch.calendarEvents.setFilterOptions({
          dayoffFilters: filterOptions.dayoff_filter,
        })
      }
      if (
        filterOptions.highlights &&
        Object.keys(filterOptions.highlights).length > 0
      ) {
        dispatch.calendarEvents.setHighlightOptions(filterOptions.highlights)
      }
    },
    async saveFilterOptions(data) {
      const filterOptions = await api.saveCalendarFilter({
        provider_filter: data.providersFilters,
        job_filter: data.jobsFilters,
        dayoff_filter: data.dayoffFilters,
        highlights: data.highlights,
      })
      if (
        filterOptions.provider_filter &&
        Object.keys(filterOptions.provider_filter).length > 0
      ) {
        dispatch.calendarEvents.setFilterOptions({
          providersFilters: filterOptions.provider_filter,
        })
      }
      if (
        filterOptions.job_filter &&
        Object.keys(filterOptions.job_filter).length > 0
      ) {
        dispatch.calendarEvents.setFilterOptions({
          jobsFilters: filterOptions.job_filter,
        })
      }
      if (
        filterOptions.dayoff_filter &&
        Object.keys(filterOptions.dayoff_filter).length > 0
      ) {
        dispatch.calendarEvents.setFilterOptions({
          dayoffFilters: filterOptions.dayoff_filter,
        })
      }
      if (
        filterOptions.highlights &&
        Object.keys(filterOptions.highlights).length > 0
      ) {
        dispatch.calendarEvents.setHighlightOptions(filterOptions.highlights)
      }
    },
  }),
  selectors: (slice, createSelector) => ({
    filteredCalendarData() {
      return slice((state): FilteredEventsType => {
        const {
          events: {
            draftEvents,
            assignments,
            flags,
            daysOff,
            requests,
            vacations,
          },
          requestTypes,
          calendarConfig: { filterOptions },
        } = state

        const filterFunc = (assignment: GroupedAssignment) => {
          const { jobIds } = filterOptions.jobsFilters
          const { providerIds } = filterOptions.providersFilters

          const jobIdsCondition = jobIds
            ? jobIds.includes(assignment.job?.jobid)
            : true

          const providerIdsCondition = providerIds
            ? providerIds.includes(assignment.provider.providerid)
            : true

          const isMultiAssignmentFilterCondition =
            !!assignment.eventid &&
            assignment.additional_event_assignments?.some(
              (additionalAssignment) =>
                providerIds
                  ? providerIds.includes(
                      additionalAssignment.provider.providerid
                    )
                  : true
            )

          const isMultiDraftAssignmentFilterCondition =
            !!assignment.draft_eventid &&
            assignment.additional_event_assignments?.some(
              (additionalDraftAssignment) =>
                providerIds
                  ? providerIds.includes(
                      additionalDraftAssignment.provider.providerid
                    )
                  : true
            )

          return (
            jobIdsCondition &&
            (providerIdsCondition ||
              isMultiAssignmentFilterCondition ||
              isMultiDraftAssignmentFilterCondition)
          )
        }

        const filterJobsFunc = (assignment: GroupedAssignment) => {
          const { jobIds } = filterOptions.jobsFilters

          const jobIdsCondition = jobIds
            ? jobIds.includes(assignment.job?.jobid)
            : true

          return jobIdsCondition
        }

        const filterProvidersFunc = (assignment: GroupedAssignment) => {
          const { providerIds } = filterOptions.providersFilters

          const providerIdsCondition = providerIds
            ? providerIds.includes(assignment.provider.providerid)
            : true

          const isMultiAssignmentFilterCondition =
            !!assignment.eventid &&
            assignment.additional_event_assignments?.some(
              (additionalAssignment) =>
                providerIds
                  ? providerIds.includes(
                      additionalAssignment.provider.providerid
                    )
                  : true
            )

          const isMultiDraftAssignmentFilterCondition =
            !!assignment.draft_eventid &&
            assignment.additional_event_assignments?.some(
              (additionalDraftAssignment) =>
                providerIds
                  ? providerIds.includes(
                      additionalDraftAssignment.provider.providerid
                    )
                  : true
            )

          return (
            providerIdsCondition ||
            isMultiAssignmentFilterCondition ||
            isMultiDraftAssignmentFilterCondition
          )
        }

        const filterFlagsFunc = (flag: ScheduleDateType) => {
          const { jobIds } = filterOptions.jobsFilters

          return jobIds ? jobIds.includes(flag.jobid) : true
        }

        const filterDaysOffFunc = (dayoff: Dayoff) => {
          const { requestTypeIds = [] } = filterOptions.dayoffFilters
          const dayoffTypeIds = requestTypes
            .filter((rt) => requestTypeIds.includes(rt.request_typeid))
            .map((rt) => rt.dayoff_typeid)
          return dayoffTypeIds.includes(dayoff.dayoff_typeid)
        }

        const filterRequestsFunc = (request: IRequest) => {
          const { requestStatusIds, requestTypeIds } =
            filterOptions.dayoffFilters
          return (
            (requestStatusIds === undefined ||
              requestStatusIds.includes(request.request_statusid)) &&
            (requestTypeIds === undefined ||
              requestTypeIds.includes(request.request_typeid))
          )
        }

        const mapFunc = (
          assignment: GroupedAssignment,
          filterFunction: (assignment: GroupedAssignment) => boolean | undefined
        ) => {
          if (
            assignment.additional_event_assignments &&
            assignment.additional_event_assignments.length > 0
          ) {
            return {
              ...assignment,
              additional_event_assignments:
                assignment.additional_event_assignments
                  .map((addAssignment) => ({
                    ...addAssignment,
                    job: assignment?.job,
                  }))
                  .filter(filterFunction),
            }
          }

          if (
            assignment.split_shiftid &&
            assignment.additional_split_event_assignments
          ) {
            return {
              ...assignment,
              additional_split_event_assignments:
                assignment.additional_split_event_assignments.filter(
                  filterFunction
                ),
            }
          }

          return assignment
        }

        return {
          ...state.events,
          assignments: assignments
            .map((assignment) => mapFunc(assignment, filterFunc))
            .filter(filterFunc),
          jobsFilteredAssignments: assignments
            .map((assignment) => mapFunc(assignment, filterJobsFunc))
            .filter(filterJobsFunc),
          draftEvents: draftEvents
            .map((assignment) => mapFunc(assignment, filterFunc))
            .filter(filterFunc),
          jobsFilteredDraftEvents: draftEvents
            .map((assignment) => mapFunc(assignment, filterJobsFunc))
            .filter(filterJobsFunc),
          providersFilteredAssignments: assignments
            .map((assignment) => mapFunc(assignment, filterProvidersFunc))
            .filter(filterProvidersFunc),
          providersFilteredDraftEvents: draftEvents
            .map((assignment) => mapFunc(assignment, filterProvidersFunc))
            .filter(filterProvidersFunc),
          flags: flags.filter(filterFlagsFunc),
          daysOff: daysOff.filter(filterDaysOffFunc),
          requests: requests.filter(filterRequestsFunc),
          vacations: vacations.filter(
            () => filterOptions.dayoffFilters.showVacations
          ),
        }
      })
    },
  }),
})
