import React, { useEffect, useState, useMemo } from "react"
import Table from "@app/components/Table"
import { H5 } from "@app/components/Typography"
import { ProviderDefaultColor } from "@app/utils/constants"
import cx from "classnames"
import css from "./AvailableProviders.module.scss"
import api, { useRequest } from "@app/services/api"
import {
  AvailableUnavailableProviderData,
  FormattedProvider,
  ProviderRules,
} from "./type"

type Props = {
  jobId: number
  edate: string
}

const formatProvider = (
  provider: Provider & { rulesApplied: ProviderRules }
): FormattedProvider => {
  const formattedProvider: FormattedProvider = {
    id: provider.providerid,
    color: provider.provider_color ?? ProviderDefaultColor,
    name: `${provider.display_name} - ${provider.firstname} ${provider.lastname}`,
    available: provider.rulesApplied.length === 0,
    violation:
      provider.rulesApplied.length > 0
        ? provider.rulesApplied.map((rule) => rule.message).join("\n")
        : undefined,
  }
  return formattedProvider
}

const AvailableProviders: React.FC<Props> = ({ jobId, edate }) => {
  const [{ availableProviders, unavailableProviders }, setProviderTableData] =
    useState<AvailableUnavailableProviderData>({
      availableProviders: [],
      unavailableProviders: [],
    })

  const { data: providersWithRules } = useRequest([
    api.getProvidersV3,
    { jobid: jobId, date: edate },
  ])

  useEffect(() => {
    if (!Array.isArray(providersWithRules)) return

    const categorizedProviders = providersWithRules.reduce(
      (providerList: AvailableUnavailableProviderData, provider) => {
        const formattedProvider = formatProvider(provider)

        if (provider.rulesApplied.length > 0) {
          providerList.unavailableProviders.push(formattedProvider)
        } else {
          providerList.availableProviders.push(formattedProvider)
        }
        return providerList
      },
      { availableProviders: [], unavailableProviders: [] }
    )

    setProviderTableData(categorizedProviders)
  }, [providersWithRules])

  const availableProvidersColumns = useMemo(
    () => [
      {
        title: "Available Providers",
        dataKey: "name",
        render: (name: string, provider: FormattedProvider) => (
          <H5 ellipsis prefixDotColor={provider.color || ProviderDefaultColor}>
            {name}
          </H5>
        ),
      },
    ],
    []
  )

  const unavailableProvidersColumns = useMemo(
    () => [
      {
        title: "Unavailable Providers",
        dataKey: "name",
        ellipsis: true,
        render: (name: string, provider: FormattedProvider) => (
          <H5 ellipsis prefixDotColor={provider.color || ProviderDefaultColor}>
            {name}
          </H5>
        ),
        width: "50%",
      },
      {
        title: "Reason",
        dataKey: "violation",
        width: "50%",
        ellipsis: true,
      },
    ],
    []
  )

  return (
    <>
      <Table
        className={cx("table-bordered", css.ruleFilteredProviders)}
        striped={false}
        data={availableProviders}
        columns={availableProvidersColumns}
      />
      <Table
        className={cx("table-bordered", css.ruleFilteredProviders)}
        striped={false}
        data={unavailableProviders}
        columns={unavailableProvidersColumns}
      />
    </>
  )
}

export default AvailableProviders
