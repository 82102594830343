import React, { useCallback, useMemo, useState } from "react"
import Button from "@app/components/Button"
import { Select } from "@app/components/Form"
import { H5 } from "@app/components/Typography"
import CheckIcon from "@material-ui/icons/Check"
import { useDispatch, useSelector } from "@app/models"
import {
  DefaultCalendarColor,
  filterJobTypesOptions,
} from "@app/utils/constants"
import { Provider as CalendarProvider } from "@app/containers/spa/WhiteboardCalendar/data"
import css from "./JobsMenu.module.scss"
import SelectedJobMenu from "./SelectedJobMenu"

interface JobsMenuProps {
  jobsData: JobAssignment[]
  providersData: CalendarProvider[]
}

export const JobsMenu = ({ jobsData, providersData }: JobsMenuProps) => {
  const [jobSubFilters, setJobSubFilters] = useState<number[]>([])

  const dispatch = useDispatch()

  const { selectedJob } = useSelector(
    (state) => state.calendarEvents.calendarConfig
  )

  const filteredData = useMemo(
    () =>
      jobSubFilters.length > 0
        ? jobsData.filter((item) => jobSubFilters?.includes(item.job_typeid))
        : jobsData,
    [jobsData, jobSubFilters]
  )

  const handleJobSubFilterList = useCallback((selectedTypeIds: number[]) => {
    setJobSubFilters(selectedTypeIds)
  }, [])

  const handleCancel = () => {
    dispatch.calendarEvents.setSelectedProvider(undefined)
    dispatch.calendarEvents.setSelectedJob(undefined)
    dispatch.quickAssignments.resetQuickAssignments()
  }

  const onSelectJob = (job: JobAssignment) => {
    const isSelected = selectedJob?.jobid === job.jobid
    if (isSelected) {
      dispatch.calendarEvents.setSelectedJob(undefined)
    } else {
      dispatch.calendarEvents.setSelectedJob(job)
    }
  }

  if (selectedJob && selectedJob?.jobid) {
    return <SelectedJobMenu providersData={providersData} />
  }

  return (
    <div className={css.quickModalBodyContent}>
      <p className={css.quickModalDescription}>
        Select a Job for Quick Assign Mode
      </p>
      <Select
        multiple
        value={jobSubFilters}
        options={filterJobTypesOptions}
        onChange={handleJobSubFilterList}
        placeholder="All Jobs Types"
        className={css.selectMultiJob}
      />
      <div className={css.quickModalContainer}>
        <ul className={css.unorderedListContainer}>
          {filteredData.length === 0 && <div>No jobs found</div>}
          {filteredData.map((job) => (
            <li
              key={job.jobid}
              className={css.customOption}
              style={{
                borderLeft: `10px solid ${job.color ?? DefaultCalendarColor}`,
              }}
              onClick={() => onSelectJob(job)}
            >
              <div className={css.optionInnerContainer}>
                <H5 ellipsis>{job.abbrev}</H5>
                <div
                  className={`${css.checkboxContainer} ${
                    selectedJob?.jobid === job.jobid ? css.checkedContainer : ""
                  }`}
                >
                  {selectedJob?.jobid === job.jobid ? (
                    <CheckIcon className={css.checkIcon} />
                  ) : (
                    <div className={css.uncheckedIcon}></div>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className={css.buttonContainer}>
        <Button shape="pill" variant="light" size="sm" onClick={handleCancel}>
          Clear
        </Button>
      </div>
    </div>
  )
}
